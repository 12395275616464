@use 'sass:math'; @import '~@scentregroup/ui/themes/westfield.module.scss';
@import 'tools/fonts.module.scss';
@import 'tools/color.module.scss';

body {
  @include ceraPro;
  color: color('Licorice');
  margin: 0;
  padding: 0;
}

button {
  background: transparent;
  border: none;
  color: color('Licorice');
}

// this needs to be moved to ui
// but for the time being it can
// live here
input:disabled,
textarea:disabled {
  font-style: normal;
  background-color: color('Grey Light');
  color: color('Grey Dark');
  border-color: color('Grey');
  cursor: default;
  pointer-events: none;
}
