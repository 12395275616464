@mixin ceraPro() {
  font-family: 'Cera PRO', '-apple-system', 'Roboto', 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin ceraProItalic() {
  font-family: 'Cera PRO', '-apple-system', 'Roboto', 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
}

@mixin ceraProBold() {
  font-family: 'Cera PRO', '-apple-system', 'Roboto', 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
  font-weight: bold;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin ceraProBoldItalic() {
  font-family: 'Cera PRO', '-apple-system', 'Roboto', 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
  font-weight: bold;
  font-style: italic;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
// ===

// Abstractions
@mixin bodyFont() {
  @include ceraPro;
}

@mixin bodyFontBold() {
  @include ceraProBold;
}

@mixin bodyFontItalic() {
  @include ceraProItalic;
}

@mixin headingFont() {
  @include ceraProBold;
}

@mixin condensedFont() {
  @include ceraPro;
}
